<template>
  <v-app class>
    <div>
     <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 mt-8 d-flex ">
            <div class="col-md-11">
              <h1>Vacation Requests</h1>
            </div>
            <div class="col-md-1">
              <Button :btnType="'Submit'" :label="'+'" @onClick="Add" class="mr-2" />
            </div>
          </div>
          <v-text-field v-model="searchPersonnel"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details></v-text-field>
          <div :style="{visibility: loading ? 'visible' : 'hidden'}" class="text-center">
            <v-progress-circular indeterminate
                                 color="primary"></v-progress-circular>
          </div>
          <v-data-table :headers='headers'
                        :search="searchPersonnel"
                        :items="leaves"
                        :single-select="false"
                        v-model="selected"
                        item-key="requestId">
            <template v-slot:item.dates="{ item }">
              <div class="d-flex">
                <v-icon medium @click="viewDateModal(item)">mdi-calendar</v-icon>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <Button v-if="item.status === 'Pending supervisor' "  :btnType="'Cancel'" :label="'Delete'" @onClick="openDeleteModal(item)" class="mr-2" />
                <Button v-if="getUserRole('Admin')" :btnType="'Submit'" :color="'primary'" :label="'Forward'" @onClick="openPersonnelSearch(item)" class="mr-2"/>
              </div>
            </template>
          </v-data-table>

          <!-- view dates modal   -->
          <v-dialog v-model="dateDialog" persistent max-width="600">
            <v-card>
              <v-card-title class="headline text-center"><h3>Vacation Days</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-text class="mt-5">
                <v-data-table :headers='leaveDaysHeaders'
                              :items="leaveDays">
                </v-data-table>
              </v-card-text>
                <v-divider></v-divider>

                <v-row class="px-5">
                  <v-card width="600" elevation="0">
                    <v-tabs v-model="tab">
                    <v-tab href="#tab-1" class="tab-title">Approver</v-tab>
                    <v-tab href="#tab-2">Log</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                      <v-card class="pt-2" flat>
                        <div v-if="loadingLogs" class="text-center">
                          <v-progress-circular indeterminate
                                               color="primary"></v-progress-circular>
                        </div>
                        <v-col v-else class="pt-3 pr-1 pl-1" :md="6" v-for="(approver, index) in approvers" :key="index">
                          <p class="text-left mb-1 approval-title">Waiting on ({{approver.approver}}): {{approver.approvers[0]}}</p>
                          <!-- <v-select
    :items="approver.approvers"
    outlined
    dense
    :value="approver.approvers[0]"
  ></v-select> -->
                        </v-col>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                      <v-card class="pt-2" flat>
                        <div v-if="loadingLogs" class="text-center">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </div>
                        <v-data-table v-else
                                      :headers='logHeaders'
                                      :items="logs">
                          <template v-slot:item.activityDate="{ item }">
                            <span>{{ item.activityDate | formatDate }}</span>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  </v-card>
                </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dateDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Delete modal  -->
          <v-dialog v-model="dialog" persistent max-width="400">
            <v-card>
              <v-card-title class="headline text-center"><h3>Do you want to delete ?</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <Button :label="'Yes'"
                        :btnType="'Submit'"
                        @onClick="deleteOk"
                        class="mr-4" />
                <v-btn color="green darken-1" text @click="dialog = false">No</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </div>
    </div>
     <PersonnelSearch :dialog="personnelSearch" @selectPer="forward ($event)" @commentPer="getComment" :mType="'forward'"  @close="personnelSearch = false" :type="'employee'" :title="'Personnel'">
       <Button
        :label="'Forward'"
        :btnType="'Submit'"
        @onClick="forwardRequest"
        :disabled="DelegateEmail === null"
        :isLoading="forwarding"
        class="mr-4"
      />
     </PersonnelSearch>
  </v-app>
</template>
<script>

import {
  FETCH_LEAVE,
  FORWARD_REQUEST
} from '@/store/action-type'

import Button from '@/components/ui/form/Button.vue'
import { requestService } from '@/services'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import moment from 'moment'

export default {
  components: {
    Button,
    PersonnelSearch
  },
  data () {
    return {
      tab: null,
      leavesData: true,
      alertMessage: '',
      alert: false,
      searchPersonnel: '',
      valid: false,
      dialog: false,
      data: '',
      dateDialog: false,
      alertType: '',
      rejectDialog: false,
      commnet: '',
      action: '',
      loading: false,
      item: '',
      bulk: false,
      personnelSearch: false,
      DelegateCAI: null,
      DelegateName: null,
      DelegateEmail: null,
      forwarding: false,
      loadingLogs: false,
      selected: [],
      formData: {
        comments: '',
        id: ''
      },
      leaveDays: [],
      actions: [{ id: 1, name: 'Approve' }, { id: 2, name: 'Reject' }],
      approvers: [],
      logs: [],
      headers: [
        {
          text: 'Full Name',
          value: 'employeeName'
        },
        {
          text: 'Company Name',
          value: 'companyName'
        },
        {
          text: 'Request Type',
          value: 'requestType'
        },
        {
          text: 'Date',
          value: 'dates'
        },
        {
          text: 'Approval Status',
          value: 'status'
        },
        {
          text: 'Total vacation days',
          value: 'requestCount'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ],
      leaveDaysHeaders: [
        {
          text: 'Day',
          value: 'Day'
        },
        {
          text: 'Date',
          value: 'date'
        }
      ],
      logHeaders: [
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Action by',
          value: 'username'
        },
        {
          text: 'Comment',
          value: 'comment',
          width: '200px'
        },
        {
          text: 'Date',
          value: 'activityDate',
          width: '150px'
        }
      ]
    }
  },
  computed: {
    leaves () {
      return this.$store.getters.leaves
    },
    currentUser () {
      return this.$store.getters.users
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    openPersonnelSearch (item) {
      this.personnelSearch = true
      this.item = item
    },

    viewDateModal (item) {
      console.log(item)
      this.dateDialog = true
      this.loadingLogs = true
      requestService.leaveApprovalLogs(item.guid).then(({ data }) => {
        this.approvers = data.currenApprover
        this.logs = data.logs
      }).catch(() => {

      }).finally(() => {
        this.loadingLogs = false
      })

      const re = /\s*(?:,|$)\s*/
      const dateArr = item.dates.split(re)

      const dateStructure = []

      dateArr.forEach(date => dateStructure.push({ Day: moment(new Date(date)).format('dddd'), date: date }))
      dateStructure.pop()
      this.leaveDays = dateStructure.reverse()
    },
    Add () {
      this.$router.push('/leave/add')
    },
    openDeleteModal (item) {
      this.data = item
      console.log(item)
      this.dialog = true
    },
    deleteOk () {
      requestService.deleteLeave(this.data.guid).then(() => {
        this.showAlertMessage('Deleted Successfully', 'success')
        this.dialog = false
        this.$router.go()
      }).catch((error) => {
        console.log(error)
        this.showAlertMessage('Delete failed', 'error')
      })
    },
    getUserRole (role) {
      if (this.currentUser.roles.includes(role)) {
        return true
      } else {
        return false
      }
    },
    forward (item) {
      this.DelegateEmail = item.emailAddress
      this.DelegateName = item.name
      this.DelegateCAI = item.id
    },
    getComment (comment) {
      this.comment = comment
    },
    getPendingLeave () {
      this.loading = true
      this.$store
        .dispatch(FETCH_LEAVE)
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            this.loading = false
          }
        })
    },
    forwardRequest () {
      this.forwarding = true
      let data = {
        ItemGuid: this.item.guid,
        // "ItemId":428,//This should be the Employee Id
        'DelegateCAI': this.DelegateCAI,
        'DelegateName': this.DelegateName,
        'DelegateEmail': this.DelegateEmail,
        'Comment': this.comment
      }

      this.$store.dispatch(FORWARD_REQUEST, { data, type: 'Leave' }).then((res) => {
        this.showAlertMessage('Forwarded successful', 'success')
      }).catch(() => {
        this.showAlertMessage('Failed to Forward try again', 'error')
      }).finally(() => {
        this.personnelSearch = false
        this.forwarding = false
        this.DelegateCAI = null
        this.DelegateName = null
        this.DelegateEmail = null
      })

      console.log('forward data', data)
    }
  },
  mounted () {
    this.getPendingLeave()
    // this.$store
    //   .dispatch(FETCH_LEAVE)
    //   .then(() => {})
    //   .catch((error) => {
    //     console.log(error)
    //     if (error) {
    //       this.showAlertMessage('There are no items to display', 'error')
    //     }
    //   })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.action-select {
 padding-top: 24px;
}
.approval-title{
      font-size: 14px;
    font-weight: bold;
    margin-bottom: 40px;
}
.v-tab--active{
   background-color: #faf8f8 !important;
   color: #0b2d71 !important
}
.v-tabs-slider-wrapper{
  background-color: #0b2d71 !important
}
</style>
